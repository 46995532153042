import React from 'react'
import UtilityPanel from '@scentregroup/shared/hub-components/utility-panel'
import { AsMeasured } from '@scentregroup/shared/hub-components/as-measured'
import { useAnalytics } from '@scentregroup/shared/analytics'

import { transformContentClickedItemsProperties } from '@scentregroup/shared/analytics-segment/events/content-item'
import { SGPageTemplateContentCollection } from '@scentregroup/shared/types/page-templates'
import { ModuleProps } from '../../types'

const noop = (): void => {}

function UtilityPanelModule(
  props: ModuleProps<SGPageTemplateContentCollection>
): JSX.Element | null {
  const { trackEvent } = useAnalytics()

  const { module } = props

  if (module.items.length === 0) {
    return null
  }

  const triggerOnClick = (index: number): void => {
    trackEvent({
      object: 'Content',
      action: 'Clicked',
      properties: transformContentClickedItemsProperties(module, index),
    })
  }

  return (
    <UtilityPanel
      items={module.items.map(item => ({
        ...item,
        viewItem: noop,

        as: asProps => (
          <AsMeasured
            {...asProps}
            metadata={item.analyticsMetadata}
            slot={props.module.analyticsMetadata?.displayId}
          />
        ),
      }))}
      as={asProps => (
        <AsMeasured
          {...asProps}
          moduleKind={props.module.kind}
          moduleMetadata={props.module.analyticsMetadata}
        />
      )}
      clickEvent={triggerOnClick}
    />
  )
}

export default UtilityPanelModule
