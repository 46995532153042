import React from 'react'
import { Text } from '@hub/text'
import { Stack } from '@hub/stack'
import { H3 } from '@hub/heading'
import { CoreContainer } from '@hub/core-container'
import { Link, LinkBox, LinkOverlay } from '@hub/link'
import { Grid } from '@hub/grid'
import { Box } from '@hub/box'
import { Cluster } from '@hub/cluster'
import { useToken, As } from '@hub/design-system-base'
import {
  CloudinaryImage,
  IMAGE_SIZES_GRID_PRIMARY,
  IMAGE_SIZES_GRID_SECONDARY,
} from '../cloudinary-image'
import trackClickEvents, { categories } from '../analytics/trackEvents'
import { TrackBox, TrackLinkBox } from '../../analytics-observer'

const MAX_PRIMARY_RETAILERS = 6

export interface RetailerDetails {
  title: string
  logoUrl: string
  alternativeLogoUrls?: {
    coloured: string
    transparent: string
  }
  shortDescription?: string
  url: string
  as?: As
  index?: number
}

interface StoreGridProps {
  /** Title for the store grid, defaulted to 'Shop the stores you love' */
  title?: string
  /** Subtitle for the store grid, defaulted to 'Delivered in 2 hours or click & collect in 30 minutes' */
  subtitle?: string
  /** The 'shop all' link at the bottom of the component */
  callToAction?: {
    url: string
    label?: string
  }
  /** Provide a list of all retailers to display and it will be split between major and minor */
  retailers?: RetailerDetails[]
  /** Information on the smaller store logos. These will always be two rows */
  secondaryRetailers?: RetailerDetails[]
  /** Information on the larger store logos - there should be exactly six of these provided - other numbers may cause problems */
  primaryRetailers?: RetailerDetails[]
  as?: As
  clickEvent?: (index: number) => void
  enableH1Heading?: boolean
}

/**
 * Example usage:

  <StoreGrid
    shopAllLink="https://placekitten.com/"
    primaryRetailers={[...new Array(6)].map(() => ({
      src: 'https://placekitten.com/500/500',
      image: {
        description: 'kitten',
        src: 'https://placekitten.com/500/500',
      },
    }))}
    secondaryRetailers={[...new Array(40)].map(() => ({
      src: 'https://placekitten.com/100/200',
      image: {
        src: 'https://placekitten.com/700/700',
        description: 'a cute cat',
      },
    }))}
  />
*/
export const StoreGrid: React.FC<React.PropsWithChildren<StoreGridProps>> = ({
  title,
  subtitle,
  retailers = [],
  secondaryRetailers = retailers?.slice(MAX_PRIMARY_RETAILERS),
  primaryRetailers = retailers?.slice(0, MAX_PRIMARY_RETAILERS),
  callToAction,
  as,
  clickEvent,
  enableH1Heading: enableH1Heading,
}) => {
  const TILE_SIZE_TOKEN = 'size-9'
  const SPACING_TOKEN = 'spacing-lg'

  const spacing = useToken('space', SPACING_TOKEN)
  const tileSize = useToken('sizes', TILE_SIZE_TOKEN)
  const textSecondary = useToken('color', 'textSecondary')
  const borderTertiary = useToken('color', 'borderTertiary')
  return (
    <CoreContainer as={as}>
      <Stack sx={{ paddingBottom: 'spacing-sm' }}>
        {title && (
          <H3 as={enableH1Heading ? 'h1' : 'h3'} align="center">
            {title}
          </H3>
        )}
        {subtitle && (
          <Text align="center" color={textSecondary}>
            {subtitle}
          </Text>
        )}
      </Stack>
      <Stack
        gap={SPACING_TOKEN}
        sx={{
          overflow: 'hidden',
        }}
      >
        <Grid
          maxWidth={['size-md', null, 'size-sm']}
          sx={{
            gridGap: ['spacing-sm', 'spacing-md'],
          }}
          role="list"
        >
          {primaryRetailers.map(({ logoUrl, title, url, as, index }) => (
            // SGA11y: The role attribute is used to indicate the purpose of the element within the page.
            // The listitem role is used to indicate that the element is a list item.
            <TrackBox key={url} role="listitem">
              <LinkBox
                sx={{
                  border: 'solid 1px',
                  borderColor: borderTertiary,
                  padding: 'spacing-sm',
                }}
                as={as}
                onClick={() => {
                  if (clickEvent) {
                    clickEvent(index ?? 0)
                  }
                  trackClickEvents.clicked(
                    categories.STORE_GRID,
                    `Primary Retailer: ${title}`
                  )
                }}
              >
                <LinkOverlay href={url}>
                  <CloudinaryImage
                    ratio="1"
                    imageSetOrImage={{
                      url: logoUrl,
                      alt: title,
                      type: 'SQUARE',
                    }}
                    minWidth="size-sm"
                    resizeMode="pad"
                    sizes={IMAGE_SIZES_GRID_PRIMARY(primaryRetailers.length)}
                  />
                </LinkOverlay>
              </LinkBox>
            </TrackBox>
          ))}
        </Grid>

        {secondaryRetailers.length > 0 && (
          <Cluster
            minSize={TILE_SIZE_TOKEN}
            gap={SPACING_TOKEN}
            align="center"
            justify="center"
            sx={{
              marginTop: `calc(${spacing} / 2 * -1)`,
              maxHeight: `calc((${tileSize} * 2) + (${spacing} * 2))`,
            }}
            role="list"
          >
            {secondaryRetailers.map(
              ({ alternativeLogoUrls, logoUrl, title, url, as, index }) => (
                <Box
                  key={url}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderColor: borderTertiary,
                  }}
                  role="listitem"
                  as={as}
                >
                  <TrackLinkBox
                    onClick={() => {
                      if (clickEvent) {
                        clickEvent(index ?? 0)
                      }
                      trackClickEvents.clicked(
                        categories.STORE_GRID,
                        `Secondary Retailer: ${title}`
                      )
                    }}
                  >
                    <LinkOverlay href={url}>
                      <Box
                        sx={{
                          filter: 'grayscale(1) opacity(0.7)',
                          maxWidth: TILE_SIZE_TOKEN,
                        }}
                      >
                        <CloudinaryImage
                          ratio="1"
                          imageSetOrImage={{
                            url:
                              alternativeLogoUrls?.coloured ??
                              alternativeLogoUrls?.transparent ??
                              logoUrl,
                            alt: title,
                            type: 'SQUARE',
                          }}
                          sizes={IMAGE_SIZES_GRID_SECONDARY}
                          minWidth={TILE_SIZE_TOKEN}
                        />
                      </Box>
                    </LinkOverlay>
                  </TrackLinkBox>
                </Box>
              )
            )}
          </Cluster>
        )}
      </Stack>
      {callToAction && (
        <Stack gap="spacing-sm" sx={{ marginTop: 'spacing-md' }}>
          <Box />
          <Text align="center">
            <Link hasUnderline href={callToAction.url}>
              {callToAction.label ?? 'Shop all stores'}
            </Link>
          </Text>
        </Stack>
      )}
    </CoreContainer>
  )
}
