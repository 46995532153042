import { NodeData } from '@contentful/rich-text-types'

export type RichTextEmbeddedEntryData = {
  [key in keyof RichTextEmbeddedEntryTargetByContentType]: {
    contentType: key
    target: RichTextEmbeddedEntryTargetByContentType[key]
  }
}[keyof RichTextEmbeddedEntryTargetByContentType]

type RichTextEmbeddedEntryTargetByContentType = {
  richTextCallToAction: RichTextEmbeddedEntryTargetRichTextCallToAction
}

type RichTextEmbeddedEntryTargetEntry<
  ContentType extends string,
  Fields extends Record<string, unknown> = Record<string, unknown>,
> = {
  sys: {
    id: string
    contentType: {
      sys: {
        id: ContentType
      }
    }
  }
  fields: Fields
}

export type RichTextEmbeddedEntryTargetRichTextCallToAction =
  RichTextEmbeddedEntryTargetEntry<
    'richTextCallToAction',
    {
      title: string
      address: string
    }
  >

export function validateEmbeddedEntryNodeData(
  data: NodeData | null | undefined
): RichTextEmbeddedEntryData | null {
  if (!data?.target) {
    return null
  }

  const contentType = extractEmbeddedEntryTargetContentType(data.target)
  if (!contentType) {
    return null
  }

  switch (contentType) {
    case 'richTextCallToAction': {
      const target = validateEmbeddedEntryTargetRichTextCallToAction(
        data.target
      )
      return target && { contentType, target }
    }
    default:
      return null
  }
}

function extractEmbeddedEntryTargetContentType(target: any): string | null {
  return target?.sys?.contentType?.sys?.id || null
}

function validateEmbeddedEntryTargetRichTextCallToAction(
  target: any & {
    sys: { contentType: { sys: { id: 'richTextCallToAction' } } }
  }
): RichTextEmbeddedEntryTargetRichTextCallToAction | null {
  const { title, address } = target.fields || {}

  if (!validateStringField(title) || !validateStringField(address)) {
    return null
  }

  return { sys: target.sys, fields: { title, address } }
}

function validateStringField(value: any): value is string {
  if (!value || typeof value !== 'string') {
    return false
  }

  return true
}
