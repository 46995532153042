// magic numbers are used to make the layout
import { CoreContainer } from '@hub/core-container'
import { As, HubStyleObject } from '@hub/design-system-base'
import { H3 } from '@hub/heading'
import { Stack } from '@hub/stack'
import { Text } from '@hub/text'
import React from 'react'
import { Cluster } from '@hub/cluster'
import { IncludeDirectLogo } from '@hub/icon/src/with-direct-logo'
import { Grid } from '@hub/grid'
import { LinkOverlay } from '@hub/link'
import { Box } from '@hub/box'
import trackClickEvents, { categories } from '../analytics/trackEvents'
import { AsMeasured } from '../as-measured'
import { CloudinaryImage } from '../cloudinary-image'
import { TrackLinkBox } from '../../analytics-observer'
import { CategoryTile } from './category-tile'
import { SGPageTemplateContentEntry } from '@scentregroup/shared/types/page-templates'

interface CategorySpotlightProps {
  title?: string | null
  text?: string | null
  items: SGPageTemplateContentEntry[]
  analyticsSlot?: string
  enableH1Heading?: boolean
  as?: As
  clickEvent: (index: number) => void
  viewEvent?: (item: SGPageTemplateContentEntry) => void
}

function makeFeatureItemsSx(numOfFeatureItems: number): HubStyleObject[] {
  const baseSX = { flexGrow: [1, 'initial'], flexShrink: 1 }

  const sideSX = {
    minWidth: ['size-13', 'size-10', 'size-15'],
    flexBasis: ['size-13', 'size-10', 'size-15'],
    flexGrow: 1,
    flexShrink: 1,
  }

  const centerSX = {
    minWidth: ['size-19', 'calc(42.5% - 16px)'],
    flexBasis: ['size-full', 'size-16', 'calc(42.5% - 16px)'],
  }

  switch (numOfFeatureItems) {
    case 1:
      return [
        {
          order: [null, 2],
          minWidth: ['size-19', '50%'],
          flexBasis: ['size-full', 'size-16', '50%'],
          ...baseSX,
        },
      ]

    case 2:
      return [
        {
          ...centerSX,
        },
        {
          ...centerSX,
        },
      ]
    case 3:
    default:
      return [
        {
          order: [null, 2],
          minWidth: ['size-19', '36%'],
          flexBasis: ['size-full', 'size-16', '36%'],
          ...baseSX,
        },
        {
          order: [null, 1],
          ...sideSX,
        },
        {
          order: [null, 3],
          ...sideSX,
        },
      ]
  }
}

const ClusterMember: React.FC<{
  item?: SGPageTemplateContentEntry
  onClick?: React.MouseEventHandler
  sx?: HubStyleObject
  analyticsSlot?: string
}> = ({ item, onClick, sx, analyticsSlot }) => {
  if (!item) {
    return null
  }
  return (
    <Box sx={sx ?? {}}>
      <CategoryTile
        as={(props: any) => (
          <AsMeasured
            {...props}
            metadata={item.analyticsMetadata}
            slot={item.orchestrationMetadata?.displayId ?? analyticsSlot}
            onClick={onClick}
          />
        )}
        {...item}
      />
    </Box>
  )
}

const GridMember: React.FC<{
  item?: SGPageTemplateContentEntry
  onClick?: React.MouseEventHandler
  analyticsSlot?: string
}> = ({ item, onClick, analyticsSlot }) => {
  if (!item) {
    return null
  }
  return (
    <TrackLinkBox
      key={item.title}
      onClick={e => {
        onClick?.(e)
        trackClickEvents.clicked(
          categories.CATEGORY_DECK,
          `Sub Category | ${item.title}`
        )
      }}
    >
      <Stack
        direction={['row', 'row', 'row', 'column']}
        shouldWrapChildren={false}
        gap={['spacing-sm', 'spacing-sm', 'spacing-sm', 'spacing-none']}
        sx={{
          alignContent: 'center',
          justifyContent: 'center',
          backgroundColor: 'smoke',
          height: 'size-full',
          padding: ['spacing-sm', 'spacing-sm', 'spacing-sm', null],
        }}
        flexGrow={0}
        as={props => (
          <AsMeasured
            {...props}
            metadata={item.analyticsMetadata}
            slot={item.orchestrationMetadata?.displayId ?? analyticsSlot}
          />
        )}
      >
        <CloudinaryImage
          ratio="1"
          imageSetOrImage={item.image}
          minWidth="size-xs"
          sizes={{ sm: 48, md: 64, lg: 100 }}
          sx={{
            marginX: [null, null, null, 'spacing-lg'],
            marginTop: [null, null, null, 'spacing-xs'],
            marginBottom: [null, null, null, 'spacing-sm'],
            flexShrink: [0, 0, 0, 1],
            flexGrow: [0, 0, 0, 1],
            flexBasis: ['size-8', 'size-8', 'size-sm', 'auto'],
          }}
        />
        <Text
          as="span"
          fontSize={['font-md', 'font-xl']}
          tone="strong"
          align={['left', 'left', 'left', 'center']}
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: [
              'flex-start',
              'flex-start',
              'flex-start',
              'center',
            ],
          }}
        >
          <LinkOverlay href={item.callToAction.url}>{item.title}</LinkOverlay>
        </Text>
      </Stack>
    </TrackLinkBox>
  )
}

export const CategorySpotlight: React.FC<
  React.PropsWithChildren<CategorySpotlightProps>
> = ({
  title,
  text,
  analyticsSlot,
  items: _items,
  as,
  clickEvent,
  enableH1Heading,
}) => {
  const items = [..._items] // take a copy to avoid mutating input value
  const featuredItems = items.splice(0, 3)
  const numOfFeatureItems = featuredItems.filter(Boolean).length
  const featureItemsSX = makeFeatureItemsSx(numOfFeatureItems)

  return (
    <CoreContainer as={as}>
      <Stack gap="spacing-sm">
        <Stack gap="spacing-none">
          <H3 align="center" as={enableH1Heading ? 'h1' : 'h3'}>
            {title && <IncludeDirectLogo title={title} />}
          </H3>
          <Text isBodyText align="center" color="quartz">
            {text}
          </Text>
        </Stack>
        <Stack gap={['spacing-sm', 'spacing-md']}>
          <Cluster
            wrap="wrap"
            gap={['spacing-sm', 'spacing-md']}
            align="center"
            justify="center"
          >
            {featuredItems.map((item, index) => (
              <ClusterMember
                key={item.title}
                sx={featureItemsSX[index]}
                onClick={() => clickEvent(index)}
                item={item}
                analyticsSlot={analyticsSlot}
              />
            ))}
          </Cluster>
          <Grid
            gap={['spacing-sm', 'spacing-md']}
            templateColumns={[
              'repeat(2, 1fr)',
              'repeat(3, 1fr)',
              null,
              'repeat(6 ,1fr)',
            ]}
            maxWidth={['size-lg', 'size-lg', 'size-lg', 'size-md']}
          >
            {items.map((item, index) => (
              <GridMember
                item={item}
                key={item.title}
                onClick={() => clickEvent(index + 3)}
                analyticsSlot={analyticsSlot}
              />
            ))}
          </Grid>
        </Stack>
      </Stack>
    </CoreContainer>
  )
}
