import React from 'react'
import { CoreContainer } from '@hub/core-container'
import { Feature } from '@scentregroup/shared/hub-components/feature'
import { AsMeasured } from '@scentregroup/shared/hub-components/as-measured'
import { useAnalytics } from '@scentregroup/shared/analytics'

import { transformContentClickedItemsProperties } from '@scentregroup/shared/analytics-segment/events/content-item'
import { SGPageTemplateContentCollection } from '@scentregroup/shared/types/page-templates'
import { H3 } from '@hub/heading'
import { IncludeDirectLogo } from '@hub/icon/src/with-direct-logo'
import { ModuleProps } from '../../types'

export default function SGPageTemplateContentCollectionFeatureModule({
  module,
  enableH1Heading: enableH1Heading,
}: ModuleProps<SGPageTemplateContentCollection>): JSX.Element | null {
  const { trackEvent } = useAnalytics()
  const triggerOnClick = (index: number): void => {
    trackEvent({
      object: 'Content',
      action: 'Clicked',
      properties: transformContentClickedItemsProperties(module, index),
    })
  }

  if (module.items.length === 0) {
    return null
  }

  return (
    <CoreContainer
      as={asProps => (
        <AsMeasured
          {...asProps}
          moduleKind={module.kind}
          moduleMetadata={module.analyticsMetadata}
        />
      )}
    >
      {module.title && (
        <H3
          align="center"
          as={enableH1Heading ? 'h1' : 'h3'}
          sx={{ paddingBottom: 'spacing-sm' }}
        >
          <IncludeDirectLogo title={module.title} />
        </H3>
      )}
      <Feature
        slides={module.items}
        analyticsSlot={module.analyticsMetadata?.displayId}
        clickEvent={triggerOnClick}
        key={module.analyticsMetadata?.displayId}
      />
    </CoreContainer>
  )
}
