import React from 'react'
import { useToken, HubStyleObject } from '@hub/design-system-base'
import { Box } from '@hub/box'
import { Stack } from '@hub/stack'
import Color from 'color'

interface DotsProps {
  totalFrames: number
  scrollIndex: number
  sx?: HubStyleObject
}

const Dot: React.FC<
  React.PropsWithChildren<{
    selected?: boolean
  }>
> = ({ selected }) => (
  <Box
    sx={{
      width: 'size-2',
      height: 'size-2',
      borderRadius: 'full',
      ...(selected && { background: 'white' }),
      border: '1px solid',
      borderColor: 'white',
    }}
  />
)

export const Dots: React.FC<React.PropsWithChildren<DotsProps>> = ({
  scrollIndex,
  totalFrames,
  sx,
}) => {
  const buttonBackgroundColor = Color(useToken('colors', 'licorice'))
    .alpha(0.5)
    .rgb()
    .string()
  return (
    <Stack
      direction="row"
      shouldWrapChildren={false}
      gap="spacing-sm"
      sx={{
        background: buttonBackgroundColor,
        alignSelf: 'stretch',
        alignItems: 'center',
        ...sx,
      }}
    >
      {[...Array(totalFrames)].map((e, i) => (
        <Dot selected={scrollIndex === i} key={i} />
      ))}
    </Stack>
  )
}
