import { ContentClickedEventProperties } from '../../analytics/types'
import { SGPageTemplateContentCollection } from '@scentregroup/shared/types/page-templates'

function getHostAndProtocol(): string {
  const location = window.location

  return `${location.protocol}//${location.host}`
}

export function transformContentClickedItemsProperties(
  module: SGPageTemplateContentCollection,
  index: number
): ContentClickedEventProperties {
  const item = module.items[index]
  const url = item.callToAction.url
  const hostAndProtocol = `${getHostAndProtocol()}/`

  const content: ContentClickedEventProperties = {
    label: 'content_clicked',
    category: 'Content',
    title: item.title,
    correlation_id: item.analyticsMetadata?.correlationId ?? '',
    content_type: item.kind,
    image_url: item.image.url.replace(/^\/\//, 'https://'),
    url: url.replace(/^\//, hostAndProtocol),
    module_slug:
      item.orchestrationMetadata?.moduleSlug ??
      (module.analyticsMetadata?.position || ''),
    personalisation_method:
      item.orchestrationMetadata?.__typename ===
      'ContentOrchestrationAnalyticsMetadataPersonalised'
        ? item.orchestrationMetadata.methodUsed
        : 'NO_PERSONALISATION',
  }
  return content
}
