import React from 'react'

import { BLOCKS } from '@contentful/rich-text-types'
import { Box } from '@hub/box'
import { Stack } from '@hub/stack'
import { CloudinaryImage } from '../cloudinary-image'
import { RichText, RichTextProps } from '../rich-text'

interface FlexibleGridImage {
  url: string
  alt?: string | null
}

interface FlexibleGridItemProps {
  bodyCopy: RichTextProps['body']
  image?: FlexibleGridImage | null
}

export const FlexibleGridItem: React.FC<
  React.PropsWithChildren<FlexibleGridItemProps>
> = ({ bodyCopy, image }) => {
  return (
    <Stack direction="row" gap="spacing-md" shouldWrapChildren={false}>
      {image && (
        <Box sx={{ flexGrow: 0 }}>
          <CloudinaryImage
            ratio={1}
            imageSetOrImage={{
              url: image.url,
              type: 'SQUARE',
              alt: image.alt || '',
            }}
            minWidth="size-sm"
            maxWidth="size-sm"
          />
        </Box>
      )}
      {bodyCopy && (
        <Box>
          <RichText
            body={bodyCopy}
            sxNodes={{
              [BLOCKS.PARAGRAPH]: {
                marginTop: 'spacing-md',
                '&:first-child': { marginTop: 0 },
              },
            }}
          />
        </Box>
      )}
    </Stack>
  )
}
