import React, { ReactElement } from 'react'
import { Box } from '@hub/box'
import { HubStyleObject } from '@hub/design-system-base'
import MemberExclusiveSmallSVG from './MemberExclusiveSmall.inline.svg'
import MemberExclusiveLargeSVG from './MemberExclusiveLarge.inline.svg'

export interface MemberExclusiveLabelProps {
  sx: HubStyleObject
  display: boolean | undefined
  option: 'large' | 'small'
}

const MemberExclusiveLabel = ({
  sx,
  display,
  option,
}: MemberExclusiveLabelProps): React.ReactElement | null => {
  if (!display) {
    return null
  }
  let memberExclusive: ReactElement | null = null

  memberExclusive =
    option === 'small' ? (
      <MemberExclusiveSmallSVG />
    ) : (
      <MemberExclusiveLargeSVG />
    )

  return <Box sx={sx}>{memberExclusive}</Box>
}

export default MemberExclusiveLabel
