import React from 'react'
import { CoreContainer } from '@hub/core-container'
import { Stack } from '@hub/stack'
import { Text } from '@hub/text'
import { H3 } from '@hub/heading'
import { Link } from '@hub/link'
import { Box } from '@hub/box'
import { As, HubResponsiveArray } from '@hub/design-system-base'
import { Frame } from '@hub/frame'
import { InlineDeckReel } from './inline-deck-reel'
import {
  SGPageTemplateCallToAction,
  SGPageTemplateContentEntry,
} from '@scentregroup/shared/types/page-templates'

interface Header {
  title: string
  shortDescription?: string
  callToAction?: SGPageTemplateCallToAction
}

interface HeaderProps {
  header?: Header
  as: As
  enableH1Heading?: boolean
  display: HubResponsiveArray<'none' | 'inherit'>
}

interface InlineDeckProps {
  header?: Header
  items: (SGPageTemplateContentEntry & { displayDate?: string; as?: As })[]
  as?: As
  clickEvent: (index: number) => void
  enableH1Heading?: boolean
}

const Header: React.FC<HeaderProps> = ({
  as: As,
  header,
  enableH1Heading,
  display,
}) => {
  if (!header) {
    return null
  }
  return (
    <As
      ratio="1"
      sx={{
        width: ['size-full', null, 'size-quarter'],
        paddingY: [null, null, 'spacing-sm'],
        maxWidth: [null, null, 'size-19'],
        height: 'min-content',
        display,
      }}
    >
      <Stack
        shouldWrapChildren={false}
        sx={{
          alignItems: ['center', null, 'flex-start !important'],
          textAlign: ['center', null, 'left'],
          paddingRight: [null, null, 'spacing-xl'],
          overflow: 'visible !important',
        }}
      >
        <H3 as={enableH1Heading ? 'h1' : 'h3'}>{header.title}</H3>
        {header.shortDescription && <Text>{header.shortDescription}</Text>}
        {header.callToAction && (
          <Link
            hasUnderline
            href={header.callToAction.url}
            sx={{ display: ['none', null, 'inherit'] }}
          >
            {header.callToAction.label}
          </Link>
        )}
      </Stack>
    </As>
  )
}

export function InlineDeck({
  header,
  items,
  as,
  clickEvent,
  enableH1Heading,
}: InlineDeckProps): JSX.Element {
  return (
    <CoreContainer as={as}>
      <Stack
        direction={['column', null, 'row']}
        shouldWrapChildren={false}
        flexGrow={0}
      >
        <Header
          enableH1Heading={enableH1Heading}
          header={header}
          as={Stack}
          display={['inherit', 'inherit', 'none']}
        />
        <Header
          enableH1Heading={enableH1Heading}
          header={header}
          as={Frame}
          display={['none', 'none', 'inherit']}
        />
        <Box
          sx={{
            paddingY: ['spacing-sm', null, null],
            width: ['size-full', null, header ? '75%' : 'size-full'],
          }}
        >
          <InlineDeckReel
            items={items}
            elevateHeading={enableH1Heading}
            clickEvent={clickEvent}
          />
        </Box>
        {header?.callToAction && (
          <Link
            hasUnderline
            href={header.callToAction.url}
            sx={{ display: ['block', null, 'none'], textAlign: 'center' }}
          >
            {header.callToAction.label}
          </Link>
        )}
      </Stack>
    </CoreContainer>
  )
}
