import React, { useEffect, useRef, useState } from 'react'
import type { FC, ReactNode, RefObject } from 'react'
import { Box } from '@hub/box'
import { Stack } from '@hub/stack'
import { useDebounce } from 'use-debounce'
import { CloudinaryImage } from '../cloudinary-image'
import AppleQRCode from './apple-qr-code.inline.svg'
import GoogleQRCode from './google-qr-code.inline.svg'
import { useAnalytics } from '@scentregroup/shared/analytics'

export interface DownloadWestfieldAppProps {
  children: ReactNode
  image?: {
    src: string
    alt: string
  } | null
}

function useDiamonds(): {
  diamondOneWidth: number
  diamondTwoWidth: number
  diamondThreeWidth: number
  diamondOneRef: RefObject<HTMLDivElement>
  diamondTwoRef: RefObject<HTMLDivElement>
} {
  const diamondOneRef = useRef<HTMLDivElement>(null)
  const diamondTwoRef = useRef<HTMLDivElement>(null)
  const [diamondOneWidth, setDiamondOneWidth] = useState(0)
  const [diamondTwoWidth, setDiamondTwoWidth] = useState(0)
  const [diamondThreeWidth, setDiamondThreeWidth] = useState(0)

  const [updateSize] = useDebounce((): void => {
    const diamondOneWidth = diamondOneRef.current?.getBoundingClientRect().width
    if (diamondOneWidth) {
      setDiamondOneWidth(diamondOneWidth)
    }

    const diamondTwoWidth = diamondTwoRef.current?.getBoundingClientRect().width
    if (diamondTwoWidth) {
      const diamondThreeWidth = diamondTwoWidth - 64

      setDiamondTwoWidth(diamondTwoWidth)
      setDiamondThreeWidth(diamondThreeWidth)
    }
  }, 150)

  useEffect(() => {
    // initial render
    setTimeout(() => {
      updateSize()
    }, 0)

    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [updateSize])

  return {
    diamondOneWidth,
    diamondTwoWidth,
    diamondThreeWidth,
    diamondOneRef,
    diamondTwoRef,
  }
}

export const DownloadWestfieldApp: FC<DownloadWestfieldAppProps> = ({
  children,
  image,
}) => {
  const { trackEvent } = useAnalytics()
  const {
    diamondOneWidth,
    diamondTwoWidth,
    diamondThreeWidth,
    diamondOneRef,
    diamondTwoRef,
  } = useDiamonds()
  const appleAppURL = 'https://apps.apple.com/au/app/westfield/id1050004889'
  const googlePlayURL =
    'https://play.google.com/store/apps/details?id=com.scentregroup.westfield'

  const triggerOnClick = (platform: 'apple' | 'android'): void => {
    trackEvent({
      object: 'App Download',
      action: 'Selected',
      properties: {
        name: platform === 'apple' ? 'Apple store' : 'Google Play store',
        link_url: platform === 'apple' ? appleAppURL : googlePlayURL,
        outbound: 'true',
        context: 'westfield membership',
      },
    })
  }

  return (
    <>
      <Stack
        direction={'row'}
        background={'surfaceBrandPrimary'}
        minHeight={'size-23'}
        shouldWrapChildren={false}
        flexDirection={['column', null, 'row']}
        paddingX={'spacing-md'}
        paddingY={'spacing-lg'}
      >
        <Box
          sx={{
            order: [2, null, 1],
            marginTop: ['spacing-3xl', null, 0],
          }}
        >
          {children}
          <Stack
            shouldWrapChildren={false}
            direction={'row'}
            gap={['spacing-3xl', 'spacing-2xl', null, 'size-11']}
            marginTop={[0, null, 'spacing-3xl']}
          >
            <Box
              sx={{
                maxWidth: 'size-14',
              }}
            >
              <Box sx={{ display: ['none', null, 'block'] }}>
                <AppleQRCode />
              </Box>
              <a
                href={appleAppURL}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => triggerOnClick('apple')}
              >
                <CloudinaryImage
                  sx={{
                    height: 'size-7',
                    width: 'size-14',
                    marginTop: 'spacing-2xl',
                  }}
                  imageSetOrImage={{
                    url: 'https://cam.scentregroup.io/m/beb7c689c94b4ef/original/Download-on-the-app-store.png',
                    type: 'WIDE',
                    alt: 'Download on the App Store',
                  }}
                />
              </a>
            </Box>
            <Box
              sx={{
                maxWidth: 'size-15',
              }}
            >
              <Box
                sx={{
                  maxWidth: 'size-14',
                }}
              >
                <Box sx={{ display: ['none', null, 'block'] }}>
                  <GoogleQRCode />
                </Box>
              </Box>
              <a
                href={googlePlayURL}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => triggerOnClick('android')}
              >
                <CloudinaryImage
                  sx={{
                    height: 'size-7',
                    width: 'size-14',
                    marginTop: 'spacing-2xl',
                  }}
                  imageSetOrImage={{
                    url: 'https://cam.scentregroup.io/m/51fced10c6baa259/original/google-play-badge.png',
                    type: 'WIDE',
                    alt: 'Get it on Google Play',
                  }}
                />
              </a>
            </Box>
          </Stack>
        </Box>

        <Box
          sx={{
            order: [1, null, 2],
            maxWidth: '400px',
            minWidth: ['unset', '400px'],
          }}
        >
          {/* 
              Reference divs to help with calculations with responsive diamonds... these dont show on page
              The divs wrapping the diamonds themselves aren't used as they are influenced by the diamond sizes
          */}
          <Box
            sx={{
              display: 'flex',
              minHeight: 'size-5',
            }}
          >
            <Box
              sx={{
                flexGrow: 0.95,
              }}
              ref={diamondOneRef}
            ></Box>
            <Box
              sx={{
                flexGrow: 5.05,
              }}
              ref={diamondTwoRef}
            ></Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                paddingTop: `${diamondTwoWidth / 2 - diamondOneWidth / 2}px`,
              }}
            >
              {/* diamondOne */}
              <Diamond
                width={diamondOneWidth}
                colour="shapeBrandPrimary"
              ></Diamond>
            </Box>
            <Box
              sx={{
                flexGrow: 4,
              }}
            >
              {/* diamond 2 */}
              <Diamond
                width={diamondTwoWidth}
                colour="surfaceMembershipSecondary"
              >
                {/* diamond 3 */}
                <Diamond
                  width={diamondThreeWidth}
                  colour="shapeBrandAccent02Dark"
                  zIndex={2}
                >
                  {image ? (
                    <CloudinaryImage
                      sx={{
                        position: 'absolute',
                        zIndex: 3,
                        width: 'calc(100% - 67px)',
                        height: 'auto',
                        clipPath: 'polygon(50% 0, 100% 50%, 50% 100%, 0 50%)',
                      }}
                      ratio="1"
                      imageSetOrImage={{
                        url: image.src,
                        type: 'SQUARE',
                        alt: image.alt,
                      }}
                      resizeMode="crop-center"
                      sizes={['size-16', null, 'size-22']}
                    />
                  ) : null}
                </Diamond>
              </Diamond>
            </Box>
          </Box>
        </Box>
      </Stack>
    </>
  )
}

interface DiamondProps {
  width: number
  colour: string
  zIndex?: number
  children?: ReactNode
}

const Diamond: FC<DiamondProps> = ({ width, colour, zIndex, children }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: `${width}px`,
        height: `${width}px`,
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        _after: {
          zIndex: zIndex,
          content: '""',
          display: 'block',
          background: colour,
          position: 'absolute',
          top: '15%',
          bottom: '15%',
          left: '15%',
          right: '15%',
          transform: 'rotate(45deg)',
        },
      }}
    >
      {children}
    </Box>
  )
}
