import React from 'react'

import { Box } from '@hub/box'
import { Button } from '@hub/button'
import { Link } from '@hub/link'

import { RichTextEmbeddedEntryTargetRichTextCallToAction } from '../node-data'

import { isWestfieldDomain } from '../../../../analytics/westfield-domain-checker'
import { useAnalytics } from '@scentregroup/shared/analytics'

export function RichTextEmbeddedEntryBlockRichTextCallToAction({
  target: {
    fields: { title, address },
  },
}: {
  target: RichTextEmbeddedEntryTargetRichTextCallToAction
}): JSX.Element | null {
  const { trackEvent } = useAnalytics()

  const isOutbound = !isWestfieldDomain(address)

  const triggerOnClick = (): void => {
    trackEvent({
      object: 'CTA',
      action: 'Selected',
      properties: {
        link_url: address,
        link_text: title,
        outbound: isOutbound.toString(),
      },
    })
  }

  return (
    <Box sx={{ marginTop: 'spacing-lg' }}>
      <Button
        as={Link}
        href={address}
        variant="solid"
        colorScheme="surfacePrimaryWithText"
        sx={{
          paddingX: 'spacing-lg',
          paddingY: 'spacing-md',
          minWidth: ['100%', null, 'size-13', 'size-13'],
          minHeight: 'size-8',
          textTransform: 'none', // counteracts the default uppercase transform in button theme
          textDecoration: 'none !important',
        }}
        onClick={() => triggerOnClick()}
      >
        {title}
      </Button>
    </Box>
  )
}
