import React from 'react'

import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, Document, INLINES } from '@contentful/rich-text-types'
import { Text } from '@hub/text'
import { H1, H2, H3, H4 } from '@hub/heading'
import { OrderedList, UnorderedList } from '@hub/list'
import { Link } from '@hub/link'

import { HubStyleObject } from '@hub/design-system-base'
import { RichTextEmbeddedEntry } from './embedded-entry/embedded-entry'

export interface RichTextProps {
  body?: Document | null
  options?: Options
  sxNodes?: {
    [key: string]: HubStyleObject
  }
}

export const RichText: React.FC<RichTextProps> = ({
  body,
  options = {},
  sxNodes = {},
}) => {
  if (!body) {
    return null
  }

  return <>{documentToReactComponents(body, makeOptions(options, sxNodes))}</>
}

const headerMargin = {
  marginTop: ['spacing-lg', 'spacing-2xl'],
  '&:first-child': { marginTop: 0 },
}

const makeOptions = (
  options: Options,
  sxNodes: { [key: string]: HubStyleObject }
): Options => {
  const { renderMark, renderNode, ...rest } = options

  return {
    renderMark: {
      [MARKS.BOLD]: text => (
        <Text
          as={'strong'}
          sx={{
            fontWeight: 'medium',
            lineHeight: 'base',
            ...sxNodes[MARKS.BOLD],
          }}
        >
          {text}
        </Text>
      ),
      [MARKS.SUBSCRIPT]: text => {
        if (!text) {
          return null
        }

        return (
          <Text
            as={'span'}
            className="tiny-text"
            sx={{
              fontSize: 'font-tiny',
              lineHeight: 'base',
              ...sxNodes[MARKS.SUBSCRIPT],
            }}
          >
            {text}
          </Text>
        )
      },
      ...renderMark,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        // Remove empty paragraphs placed in by rich text editor
        if (
          Array.isArray(children) &&
          (children.length === 0 ||
            (children.length === 1 && children[0] === ''))
        ) {
          return null
        }
        return (
          <Text
            sx={{
              marginTop: 'spacing-lg',
              lineHeight: 'base',
              '&:has(.tiny-text)': { marginTop: '18px', lineHeight: '18px' },
              '&:first-child': { marginTop: 0 },
              ...sxNodes[BLOCKS.PARAGRAPH],
            }}
          >
            {children}
          </Text>
        )
      },
      [BLOCKS.HEADING_1]: (node, children) => (
        <H1 sx={{ ...headerMargin, ...sxNodes[BLOCKS.HEADING_1] }}>
          {children}
        </H1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <H2 sx={{ ...headerMargin, ...sxNodes[BLOCKS.HEADING_2] }}>
          {children}
        </H2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <H3 sx={{ ...headerMargin, ...sxNodes[BLOCKS.HEADING_3] }}>
          {children}
        </H3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <H4 sx={{ ...headerMargin, ...sxNodes[BLOCKS.HEADING_4] }}>
          {children}
        </H4>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedList
          sx={{
            marginTop: 'spacing-lg',
            '&:first-child': { marginTop: 0 },
            ...sxNodes[BLOCKS.UL_LIST],
          }}
        >
          {children}
        </UnorderedList>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <OrderedList
          sx={{
            marginTop: 'spacing-lg',
            '&:first-child': { marginTop: 0 },
            ...sxNodes[BLOCKS.OL_LIST],
          }}
        >
          {children}
        </OrderedList>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => (
        <RichTextEmbeddedEntry node={node}>{children}</RichTextEmbeddedEntry>
      ),
      [INLINES.EMBEDDED_ENTRY]: (node, children) => (
        <RichTextEmbeddedEntry node={node}>{children}</RichTextEmbeddedEntry>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Link
          hasUnderline
          href={node.data.uri}
          sx={{ ...sxNodes[INLINES.HYPERLINK] }}
        >
          {children}
        </Link>
      ),
      ...renderNode,
    },
    ...rest,
  }
}

export default RichText
