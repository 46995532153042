import React from 'react'
import { Text } from '@hub/text'
import { Stack } from '@hub/stack'
import { Link } from '@hub/link'
import { Box } from '@hub/box'
import trackClickEvents, { categories, labels } from '../analytics/trackEvents'
import { useSupportingColors } from '../../hooks/use-supporting-colors'
import { ColorCardContentProps } from './types'

export const ColorCardContent: React.FC<ColorCardContentProps> = ({
  isFullWidth,
  title,
  shortDescription,
  callToAction,
  supplementaryText,
  supportingColor,
  position,
  index,
  tag,
  clickEvent,
}) => {
  const { supportingForegroundColor: primaryForegroundColor } =
    useSupportingColors(supportingColor)

  return (
    <Stack
      sx={{
        ...(isFullWidth && { width: '100%', marginX: 'auto' }),
        color: primaryForegroundColor,
        paddingBottom: 'spacing-md',
      }}
      gap="spacing-md"
    >
      <Box>
        <Text noOfLines={2} tone="strong">
          {title}
        </Text>
        <Text>{shortDescription}</Text>
      </Box>
      {supplementaryText && <Text noOfLines={3}>{supplementaryText}</Text>}
      {callToAction && (
        <Text sx={{ color: primaryForegroundColor }}>
          <Link
            hasUnderline
            href={callToAction.url}
            onClick={() => {
              if (clickEvent) {
                clickEvent(index ?? 0)
              }
              trackClickEvents.clicked(
                categories.COLOUR_CARD,
                labels.createTitleAndPosition(title, position ?? 1)
              )
            }}
            as="a"
          >
            {callToAction.label}
          </Link>
        </Text>
      )}
      {tag && (
        <Text
          sx={{
            fontSize: 'font-sm',
          }}
        >
          {tag}
        </Text>
      )}
    </Stack>
  )
}
