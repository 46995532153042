import React from 'react'

import {
  Block,
  BLOCKS,
  Inline,
  INLINES,
  Node,
} from '@contentful/rich-text-types'

import { validateEmbeddedEntryNodeData } from './node-data'

import { RichTextEmbeddedEntryBlockRichTextCallToAction } from './embedded-entry-block/rich-text-call-to-action'

export function RichTextEmbeddedEntry({
  node,
}: {
  node: Block | Inline
  children: React.ReactNode
}): JSX.Element | null {
  const data = validateEmbeddedEntryNodeData(node.data)
  if (!data) {
    return null
  }

  const nodeFamily = determineNodeFamily(node)
  if (!nodeFamily) {
    return null
  }

  switch (nodeFamily) {
    case 'block':
      switch (data.contentType) {
        case 'richTextCallToAction':
          return (
            <RichTextEmbeddedEntryBlockRichTextCallToAction
              target={data.target}
            />
          )
      }
      return null
    case 'inline':
      return null
  }
}

function determineNodeFamily(node: Node): 'block' | 'inline' | null {
  if ((Object.values(BLOCKS) as string[]).includes(node.nodeType as string)) {
    return 'block'
  }

  if ((Object.values(INLINES) as string[]).includes(node.nodeType as string)) {
    return 'inline'
  }

  return null
}
