import { MaybeURL } from '../helpers/url'

export const isWestfieldDomain = (url: string): boolean => {
  const patterns: string[] = [
    'westfield.com.au',
    'westfield.co.nz',
    'westfieldgiftcards.com.au',
    'scentregroup.io',
  ]

  const domain = new MaybeURL(url).hostname

  return patterns.some((pattern: string) => domain?.includes(pattern))
}
