import React, { Children } from 'react'
import { Box } from '@hub/box'
import { HubStyleObject } from '@hub/design-system-base'

const maxColumnAllowed = [1, 2, 3] as const
export type FlexibleGridMaxColumns = (typeof maxColumnAllowed)[number]

export interface FlexibleGridProps {
  maxColumns?: FlexibleGridMaxColumns
  sx?: HubStyleObject
}

const calculateItemFlex = (maxColumns: number): string | string[] => {
  const ratio: Record<number, string> = {
    2: '50%',
    3: '33.3%',
  }

  if (maxColumns == 1) {
    return '0 0 100%'
  }

  return [
    '0 0 100%',
    '0 0 100%',
    '0 0 calc(50% - var(--chakra-space-spacing-lg))',
    `0 0 calc(${ratio[maxColumns]} - var(--chakra-space-spacing-xl))`,
    `0 0 calc(${ratio[maxColumns]} - var(--chakra-space-spacing-5xl))`,
  ]
}

export const FlexibleGrid: React.FC<
  React.PropsWithChildren<FlexibleGridProps>
> = ({ sx, children, maxColumns = 3 }) => {
  if (!maxColumnAllowed.includes(maxColumns)) {
    throw new Error('Not a valid value for flexible grid maximum columns')
  }

  const items = Children.map(children, child => (
    <Box
      sx={{
        flex: calculateItemFlex(maxColumns),
      }}
    >
      {child}
    </Box>
  ))

  return (
    <Box
      display="flex"
      sx={{
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: [
          'spacing-lg',
          'spacing-lg',
          'spacing-lg',
          'spacing-xl',
          'spacing-5xl',
        ],
        margin: 'auto',
        maxWidth: maxColumns === 1 ? 'size-max-line-length' : undefined,
        ...sx,
      }}
    >
      {items}
    </Box>
  )
}
