import React from 'react'
import SimpleStoreGrid from '@scentregroup/shared/hub-components/store-grid/simple-store-grid'
import { AsMeasured } from '@scentregroup/shared/hub-components/as-measured'
import { useAnalytics } from '@scentregroup/shared/analytics'

import { transformContentClickedItemsProperties } from '@scentregroup/shared/analytics-segment/events/content-item'
import { SGPageTemplateContentCollection } from '@scentregroup/shared/types/page-templates'
import { ModuleProps } from '../../types'

const noop = (): void => {}

function StoreGridModule({
  module,
  enableH1Heading: enableH1Heading,
}: ModuleProps<SGPageTemplateContentCollection>): JSX.Element | null {
  const { trackEvent } = useAnalytics()

  const { title, shortDescription } = module

  if (module.items.length === 0) {
    return null
  }

  const triggerOnClick = (index: number): void => {
    trackEvent({
      object: 'Content',
      action: 'Clicked',
      properties: transformContentClickedItemsProperties(module, index),
    })
  }

  return (
    <SimpleStoreGrid
      title={title || undefined}
      shortDescription={shortDescription || undefined}
      callToAction={module.callToAction || undefined}
      enableH1Heading={enableH1Heading}
      entries={module.items.map(item => ({
        ...item,
        viewItem: noop,

        as: asProps => (
          <AsMeasured
            {...asProps}
            metadata={item.analyticsMetadata}
            slot={module.analyticsMetadata?.displayId}
          />
        ),
      }))}
      as={asProps => (
        <AsMeasured
          {...asProps}
          moduleKind={module.kind}
          moduleMetadata={module.analyticsMetadata}
        />
      )}
      clickEvent={triggerOnClick}
    />
  )
}

export default StoreGridModule
