import React from 'react'
import { Link } from '@hub/link'
import { Box } from '@hub/box'
import { useToken } from '@hub/design-system-base'
import { H5 } from '@hub/heading'
import { SpotlightEntriesProps } from '../types'
import Trackable from '../../../components/trackable'
import { trackingTypes, trackingActions } from '../../../constants'
import { CloudinaryImage } from '../../cloudinary-image'

interface SpotlightCardProps extends SpotlightEntriesProps {
  lazyLoad?: boolean
  onClick: () => void
}

const Title: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Box
      sx={{
        paddingTop: 'spacing-sm',
        flex: '1 1',
      }}
      data-test-id="spotlight-detail"
    >
      <H5 as="p" fontSize={['font-h5-mobile', null, 'font-h5']}>
        {title}
      </H5>
    </Box>
  )
}

const SpotlightCard: React.FC<SpotlightCardProps> = ({
  imageUrl,
  link,
  title,
  onClick,
  analyticsMetadata,
}) => {
  const color = useToken('color', 'textPrimary')
  const background = useToken('color', 'surfaceBrandPrimary')
  return (
    <Trackable
      {...analyticsMetadata}
      trackingAction={trackingActions.viewPromotion}
      type={trackingTypes.promotion}
    >
      <Link
        href={link}
        onClick={onClick}
        sx={{
          textDecoration: 'none',
          color,
        }}
      >
        <Box sx={{ background, padding: 'spacing-2xl' }}>
          <CloudinaryImage
            ratio={1}
            imageSetOrImage={{ url: imageUrl, alt: '', type: 'SQUARE' }}
            imageFormat="none"
          />
        </Box>
        <Title title={title} />
      </Link>
    </Trackable>
  )
}

export default SpotlightCard
