import { useToken } from '@chakra-ui/react'
import { Box } from '@hub/box'
import React from 'react'
import { CoreContainer } from '@hub/core-container'
import { Spotlight, SpotlightCard } from '../index'
import { SpotlightModuleProps } from '../types'
import { TrackBox } from '../../../analytics-observer'
import { trackClickEvent } from './analytics'

export interface SpotlightModuleWithUseAlterProps {
  moduleProps: SpotlightModuleProps
  clickEvent?: (index: number) => void
}

const ROW_GAP = ['spacing-md', 'spacing-lg', 'spacing-lg']
const COLUMN_GAP = ['spacing-sm', 'spacing-md', 'spacing-md']

const SpotlightModule = ({
  moduleProps,
  clickEvent,
}: SpotlightModuleWithUseAlterProps): JSX.Element => {
  const spaceBaseColumnGap = useToken('space', COLUMN_GAP[0])

  return (
    <CoreContainer>
      <Spotlight
        heading={moduleProps.title}
        shortDescription={moduleProps.shortDescription}
        callToAction={moduleProps.callToAction}
        entries={moduleProps.entries}
      >
        <Box
          sx={{
            display: 'flex',
            rowGap: ROW_GAP,
            columnGap: COLUMN_GAP,
            justifyContent: 'center',
            alignContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          {(moduleProps.entries || []).map((entry, index) => (
            <TrackBox
              sx={{
                flex: [
                  // Support a two column flexible layout with dynamic card width and height
                  `0 1 calc(50% - ${spaceBaseColumnGap} / 2)`,
                  // Support a flexible layout with fixed card dimensions
                  '0 0 168px',
                ],
                // We currently have no breakpoints configured for our minimum supported screen-size
                // of 320px. While Chakra does support configuring custom breakpoints, adding a new breakpoint
                // would break existing usages of Chakra's responsive array syntax thus requiring a chunky refactor.
                '@media screen and (max-width: 20rem)': {
                  // Support a flexible single card layout with dynamic width and height
                  flex: '1 1 100%',
                },
              }}
              key={entry.title}
            >
              <SpotlightCard
                {...entry}
                onClick={() => {
                  if (clickEvent) {
                    clickEvent(index)
                  }
                  trackClickEvent(
                    entry.title,
                    moduleProps.heading || moduleProps.title
                  )
                }}
              />
            </TrackBox>
          ))}
        </Box>
      </Spotlight>
    </CoreContainer>
  )
}

export default SpotlightModule
