//
// This file is auto generated. See ./README.md for details
//
import React from 'react'
import { Icon as ChakraIcon } from '@chakra-ui/react'
import {
  HubResponsiveValue,
  StandardIconSizes,
  HubStyleObject,
  forwardRef,
  IconColors,
} from '@hub/design-system-base'
import { default as DirectLogoMediumSVG } from './assets/medium/direct-logo.inline.svg'

interface DirectLogoMediumIconProps {
  boxSize?: HubResponsiveValue<StandardIconSizes>
  sx?: HubStyleObject
  ariaLabel?: string
  color?: IconColors
}

export const DirectLogoMediumIcon: React.FC<
  React.PropsWithChildren<DirectLogoMediumIconProps>
> = forwardRef<DirectLogoMediumIconProps, typeof ChakraIcon>(
  ({ boxSize = 'size-icon-sm', sx, ariaLabel, color }, ref) => (
    <ChakraIcon
      ref={ref}
      boxSize={boxSize}
      aria-label={ariaLabel}
      as={DirectLogoMediumSVG}
      sx={sx}
      color={color}
    />
  )
)
