import React, { useEffect } from 'react'
import { CoreContainer } from '@hub/core-container'
import { Text } from '@hub/text'
import { Stack } from '@hub/stack'
import trackClickEvent, {
  trackGeneralEvent,
} from '@scentregroup/shared/helpers/analytics-utils/track-event'
import analyticsUtils from '@scentregroup/shared/helpers/analytics-utils'
import { SGPageTemplateBasicContent } from '@scentregroup/shared/types/page-templates'
import { StandardButton } from '@scentregroup/shared/hub-components/standard-button'
import { ModuleProps } from '../types'

export default function BasicContent({
  module,
  disableContainerPadding = false,
  leftAlign = false,
}: ModuleProps<SGPageTemplateBasicContent> & {
  disableContainerPadding?: boolean
  leftAlign?: boolean
}): JSX.Element {
  const Wrapper = disableContainerPadding ? 'div' : CoreContainer
  const layoutAlignment = leftAlign ? 'left' : 'center'
  const { title, bodyCopy, callsToAction, titleAs } = module

  useEffect(() => {
    callsToAction.forEach(({ label }) => {
      trackGeneralEvent(
        analyticsUtils.actions.VIEW,
        analyticsUtils.categories.MARKETPLACE,
        analyticsUtils.actions.VIEW,
        label
      )
    })
  }, [callsToAction])

  return (
    <Wrapper>
      <Stack
        sx={{
          alignItems: layoutAlignment,
          justifyContent: layoutAlignment,
        }}
      >
        {title && (
          <Text
            isBodyText
            as={titleAs || 'h1'}
            fontSize={['font-h4', 'font-h3', 'font-h3']}
            tone="strong"
            align={layoutAlignment}
          >
            {title}
          </Text>
        )}
        {bodyCopy && (
          <Text
            sx={{
              marginTop: ['spacing-sm', 'spacing-sm', 'spacing-md'],
              marginBottom: ['spacing-sm', 'spacing-sm', 'spacing-md'],
              lineHeight: 'base',
            }}
            align={layoutAlignment}
          >
            {bodyCopy}
          </Text>
        )}
        <Stack
          sx={{
            marginTop: 'spacing-md',
          }}
          gap={'spacing-lg'}
          direction={['column', 'row']}
        >
          {callsToAction.map(({ url, label }) => (
            <StandardButton
              key={label}
              variant="outline"
              href={url}
              target="_self"
              onClick={() => {
                trackClickEvent(
                  analyticsUtils.categories.MARKETPLACE,
                  analyticsUtils.actions.CLICK,
                  label
                )
              }}
            >
              {label}
            </StandardButton>
          ))}
        </Stack>
      </Stack>
    </Wrapper>
  )
}
