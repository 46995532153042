import React from 'react'
import { SpotlightModule } from '@scentregroup/shared/hub-components/spotlight'
import { transformContentClickedItemsProperties } from '@scentregroup/shared/analytics-segment/events/content-item'
import { useAnalytics } from '@scentregroup/shared/analytics'

import {
  SGPageTemplateContentCollection,
  SGPageTemplateContentEntry,
} from '@scentregroup/shared/types/page-templates'
import {
  SpotlightEntriesProps,
  SpotlightModuleProps,
} from '@scentregroup/shared/hub-components/spotlight/types'
import { ModuleProps } from '../../types'

const convertItemsToEntries = (
  items: SGPageTemplateContentEntry[]
): SpotlightEntriesProps[] =>
  items.map(item => ({
    analyticsMetadata: item.analyticsMetadata,
    contentTypeId: item.kind,
    imageUrl: item.image.url,
    link: item.callToAction.url,
    title: item.title,
  }))

export default function SGPageTemplateContentCollectionSpotlightModule({
  module,
}: ModuleProps<SGPageTemplateContentCollection>): JSX.Element | null {
  const { trackEvent } = useAnalytics()

  const moduleV2ToV1: SpotlightModuleProps = {
    analyticsMetadata: module.analyticsMetadata,
    title: module.title || '',
    shortDescription: module.shortDescription || '',
    callToAction: {
      text: module.callToAction?.label,
      address: module.callToAction?.url,
    },
    entries: convertItemsToEntries(module.items),
  }

  const triggerOnClick = (index: number): void => {
    trackEvent({
      object: 'Content',
      action: 'Clicked',
      properties: transformContentClickedItemsProperties(module, index),
    })
  }

  return (
    <SpotlightModule moduleProps={moduleV2ToV1} clickEvent={triggerOnClick} />
  )
}
