import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react'
import { debounce } from 'lodash'
import {
  useToken,
  useBreakpoint,
  DEFAULT_BREAKPOINT,
  HubStyleObject,
} from '@hub/design-system-base'
import { Reel } from '@hub/reel'
import { Frame } from '@hub/frame'
import { Stack } from '@hub/stack'
import { CoreContainerContext } from '@hub/core-container'
import Color from 'color'
import {
  CloudinaryImage,
  IMAGE_SIZES_FULL_WIDTH,
  IMAGE_SIZES_HERO,
} from '../cloudinary-image'
import { AsMeasured } from '../as-measured'
import { useWithIsAboveFoldProvider } from '../is-above-fold-provider'
import { isMemberExclusive } from '../../helpers/is-member-exclusive'
import { TrackLinkBox } from '../../analytics-observer'
import { FeatureControls } from './featureControls'
import { ContentStack } from './contentStack'
import {
  SGPageTemplateContentEntry,
  SGPageTemplateImage,
} from '@scentregroup/shared/types/page-templates'

const MAX_SLIDES = 5

export interface FeatureProps {
  slides: SGPageTemplateContentEntry[]
  analyticsSlot?: string
  clickEvent?: (index: number) => void
  viewEvent?: (item: SGPageTemplateContentEntry) => void
}
export function pickBestContrast(base: string, options: string[]): string {
  let highestContrast = 0
  let winningOptionIndex = 0
  const baseColor = Color(base)
  for (let i = 0; i < options.length; i++) {
    const optionConstrast = Color(options[i]).contrast(baseColor)
    if (optionConstrast > highestContrast) {
      highestContrast = optionConstrast
      winningOptionIndex = i
    }
  }
  return options[winningOptionIndex]
}
function findSquareImage(
  image: SGPageTemplateImage,
  secondaryImage?: SGPageTemplateImage | null
): SGPageTemplateImage {
  if (image.type === 'SQUARE') {
    return image
  }
  return secondaryImage || image
}

const findTallestSlide = (slides: (HTMLElement | undefined)[]): number =>
  slides.filter(Boolean).reduce((tallestSlideHeight, slide) => {
    const stackElement = slide?.lastElementChild
    const image = stackElement?.firstElementChild?.scrollHeight || 0
    const title =
      stackElement?.lastElementChild?.firstElementChild?.scrollHeight || 0
    const button =
      stackElement?.lastElementChild?.lastElementChild?.scrollHeight || 0
    const stackHeight = image + title + button

    return tallestSlideHeight < stackHeight ? stackHeight : tallestSlideHeight
  }, 0)

interface SlideProps {
  item: SGPageTemplateContentEntry
  viewEvent?: (item: SGPageTemplateContentEntry) => void
  index: number
  analyticsSlot?: string
  setHeight: (element: HTMLElement | null) => void
  registerSlideRef: (element: HTMLElement) => void
  clickEvent?: (index: number) => void
  containerGutter: string
  // We need to pass through the sx prop to the child component because of the way <Reel> works
  sx?: HubStyleObject
}

const Slide: React.FC<React.PropsWithChildren<SlideProps>> = ({
  item,
  index,
  analyticsSlot,
  setHeight,
  registerSlideRef,
  clickEvent,
  containerGutter,
  sx,
}) => {
  const squareImagePadding = useToken('space', 'spacing-md')
  const defaultSupportingColor = useToken('colors', 'surfaceBrandPrimary')

  const { title, image, secondaryImage, shortDescription, callToAction } = item

  const hasWideImage = image.type === 'WIDE'
  const squareImage = findSquareImage(image, secondaryImage)

  // SGA11y: Images must have alternate text
  const imgWithAlt = { ...image, alt: image.alt ?? title }

  const contentActionLabel = callToAction.label
  const contentTitle = title

  return (
    <>
      {hasWideImage && (
        // We have a wide image so display in Hero Feature mode in desktop and square in mobile
        <Frame
          ratio={['1', '1', '21/9']}
          key={`${index}-wide-frame`}
          sx={{
            display: ['none', 'none', 'block'],
            height: [null, null, 'size-full'],
            ...sx,
          }}
          ref={setHeight}
        >
          <TrackLinkBox ref={registerSlideRef}>
            <Stack
              gap={['spacing-md', 'spacing-md', 'spacing-none']}
              direction={['column', 'column', 'row']}
              sx={{
                height: 'size-full',
                isolation: 'isolate',
                position: 'relative',
                width: 'size-full',
                justifyContent: [null, null, 'flex-end'],
                alignItems: [null, null, 'center'],
                paddingX: [containerGutter, containerGutter, 0],
                paddingY: [squareImagePadding, squareImagePadding, 0],
              }}
              shouldWrapChildren={false}
              flexGrow={0}
            >
              <CloudinaryImage
                ratio={'21/9'}
                imageSetOrImage={imgWithAlt}
                sx={{
                  zIndex: 1,
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                }}
                sizes={IMAGE_SIZES_FULL_WIDTH}
              />
              <ContentStack
                as={props => (
                  <AsMeasured
                    {...props}
                    metadata={item.analyticsMetadata}
                    slot={
                      item.orchestrationMetadata?.displayId ?? analyticsSlot
                    }
                  />
                )}
                position={index + 1}
                sx={{
                  zIndex: 2,
                  justifyContent: 'flex-end',
                  maxWidth: 'size-22',
                  marginRight: [
                    'spacing-xl !important',
                    null,
                    null,
                    'spacing-2xl !important',
                  ],
                  backgroundColor: defaultSupportingColor,
                  padding: 'spacing-md',
                }}
                title={contentTitle}
                shortDescription={shortDescription ?? undefined}
                callToActionLabel={contentActionLabel}
                url={callToAction.url}
                memberExclusive={isMemberExclusive(item.highlightAttribute)}
                onClick={() => {
                  if (clickEvent) {
                    clickEvent(index)
                  }
                }}
              />
            </Stack>
          </TrackLinkBox>
        </Frame>
      )}
      <TrackLinkBox
        ref={registerSlideRef}
        key={index}
        sx={{
          ...sx,
          display: ['block', 'block', hasWideImage ? 'none' : 'block'],
        }}
      >
        <Stack
          gap={['spacing-md', 'spacing-md', 'spacing-xl']}
          direction={['column', 'column', 'row']}
          shouldWrapChildren={false}
          sx={{
            alignItems: [null, null, 'center'],
            backgroundColor: defaultSupportingColor,
            paddingY: squareImagePadding,
            paddingX: [containerGutter, containerGutter, 0],
            height: [null, 'size-full', null],
          }}
          flexGrow={0}
        >
          <CloudinaryImage
            ratio="1"
            sx={{
              minWidth: [
                null,
                null,
                `calc((100% / (21 / 9)) - (2 * ${squareImagePadding}))`,
              ],
              marginLeft: [null, null, squareImagePadding],
            }}
            imageSetOrImage={squareImage}
            sizes={IMAGE_SIZES_HERO}
          />
          <ContentStack
            as={props => (
              <AsMeasured
                {...props}
                metadata={item.analyticsMetadata}
                slot={item.orchestrationMetadata?.displayId ?? analyticsSlot}
              />
            )}
            position={index + 1}
            title={contentTitle}
            shortDescription={shortDescription ?? undefined}
            callToActionLabel={contentActionLabel}
            url={callToAction.url}
            sx={{
              paddingRight: [null, null, 'spacing-xl'],
              flexGrow: [1, 1, null],
              justifyContent: [null, 'space-between', null],
            }}
            memberExclusive={isMemberExclusive(item.highlightAttribute)}
            onClick={() => {
              if (clickEvent) {
                clickEvent(index)
              }
            }}
          />
        </Stack>
      </TrackLinkBox>
    </>
  )
}

// The logic in this component can be hard to grok at first read so here is a
// pseudocode summary of the behaviour:
//
// if wide image present
//   display hero feature mode for desktop
//   display square image for mobile (fall back to only available image even if its wide)
//  else
//    display in square desktop mode with square image

export const Feature: React.FC<React.PropsWithChildren<FeatureProps>> = ({
  slides,
  analyticsSlot,
  clickEvent,
}) => {
  const breakpoint = useBreakpoint(DEFAULT_BREAKPOINT) as string
  const container = useContext(CoreContainerContext)
  const containerGutter = useToken('space', container.gutter)

  const slidesRef = useRef<HTMLElement[]>([])
  const [_windowWidth, setWindowWidth] = useState<number | undefined>()
  const [_frameHeight, setFrameHeight] = useState<number | undefined>()

  useEffect(() => {
    const DELAY = 150
    const updateSize = debounce(function (): void {
      if (window) {
        setFrameHeight(findTallestSlide(slidesRef.current))
      }
    }, DELAY)

    window.addEventListener('resize', updateSize)
    updateSize()

    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [])

  const renderControls = useMemo(
    () => FeatureControls(breakpoint),
    [breakpoint]
  )

  const [oldFrameHeight, setOldFrameHeight] = useState(0)
  // determine the height of the element inside the frame (for mobile use)
  const setHeight = useCallback(
    (element: HTMLElement | null): void => {
      if (!element) {
        return
      }
      const stackElement = element.lastElementChild?.lastElementChild
      const imageHeight = stackElement?.firstElementChild?.scrollHeight || 0
      const titleHeight =
        stackElement?.lastElementChild?.firstElementChild?.scrollHeight || 0
      const buttonHeight =
        stackElement?.lastElementChild?.lastElementChild?.scrollHeight || 0
      const stackHeight = imageHeight + titleHeight + buttonHeight
      // determine the maximum height of stacks and that will be used on the height of the frame
      setFrameHeight(
        stackHeight > oldFrameHeight ? stackHeight : oldFrameHeight
      )
      setOldFrameHeight(stackHeight)
    },
    [oldFrameHeight]
  )

  useEffect(() => {
    function updateSize(): void {
      if (window) {
        setWindowWidth(window.innerWidth)
      }
    }
    setWindowWidth(0)
    setFrameHeight(undefined)
    setOldFrameHeight(0)
    window.addEventListener('resize', updateSize)
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [])
  const slideWithFold = useWithIsAboveFoldProvider(Slide)
  return (
    <Reel
      snap
      includeOverflowInWidthCalculation
      visibleFrames={1}
      gap="spacing-none"
      renderControls={renderControls}
      containerOverflow={[containerGutter, null, 'spacing-none']}
    >
      {slides.slice(0, MAX_SLIDES).map((item, index) => {
        const SlideWithFold = slideWithFold(index < 1 ? 'above' : 'below')
        const registerSlideRef = (el: HTMLElement): void => {
          slidesRef.current[index] = el
        }
        return (
          <SlideWithFold
            key={index}
            {...{
              registerSlideRef,
              item,
              index,
              setHeight,
              analyticsSlot,
              clickEvent,
              containerGutter,
            }}
          />
        )
      })}
    </Reel>
  )
}
